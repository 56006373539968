import { graphql } from "gatsby";
import React, { useContext, useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { CloseIcon } from "../assets/icons";
import DemoModal from "../components/DemoModal";
import Pagination from "../components/Pagination";
import BlogCard from "../components/V3/Blog/BlogCard";
import BlogHero from "../components/V3/Blog/BlogHero";
import Explore from "../components/V3/Blog/Explore";
import Filter from "../components/V3/Blog/Filter";
import CustomerVerticalCard from "../components/customerLp/CustomerVerticalCard";
import Layout from "../components/layout";
import AnnotateFooter from "../components/newAnnotate/AnnotateFooter";
import SEO from "../components/seo";
import "../styles/page/blog_page.scss";
import { sanatizeSearchText } from "../utilities/helpers";
import ModalContext, { ModalProvider } from "../Contexts/openModal";
import BlogPopUpModal from "../components/V3/Blog/BlogPopUpModal";

const LIMIT_DESKTOP = 9;
const LIMIT_TABLET = 8;

/**
 * Renders the blogs cards in the grid view
 * @returns JSX.Element
 */
const BlogList = ({ data, location }) => {
  const params = new URLSearchParams(location.search);

  Object.assign(data.allPrismicBlog, {
    edges: data.allPrismicBlog?.edges.map((b) => {
      if (b?.node?.data?.custom_publish_date) {
        Object.assign(b?.node, {
          first_publication_date:
            b?.node?.data?.custom_publish_date + "T00:00:00+0000",
        });
      }
      return b;
    }),
  });

  data.allPrismicBlog?.edges?.sort(
    (a, b) =>
      new Date(b.node.first_publication_date).getTime() -
      new Date(a.node.first_publication_date).getTime()
  );

  const [blogs, setBlogs] = useState(data.allPrismicBlog?.edges || []);
  const [searchText, setSearchText] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [activeTags, setActiveTags] = useState([]);
  const [sortBy, setSortBy] = useState("");
  const isMobile = useMediaQuery({ maxWidth: 640 });
  const isTablet = useMediaQuery({ maxWidth: 1024 });
  const LIMIT = isTablet ? LIMIT_TABLET : LIMIT_DESKTOP;
  const encord_other_products = data?.prismicCaseStudies?.data?.body[1];
  const { isOpen, toggleModal, email, setEmail } = useContext(ModalContext);

  useEffect(() => {
    setCurrentPage(1);
    const regex = new RegExp(sanatizeSearchText(searchText), "iu");
    const filteredBlogs = data?.allPrismicBlog?.edges
      ?.filter((x) => x.node?.data?.title?.text?.match(regex)?.length)
      .filter(
        (_blg) =>
          _blg.node?.uid !==
          "learn-how-to-fine-tune-the-segment-anything-model-sam"
      );

    if (sortBy === "oldest") {
      filteredBlogs.sort(
        (a, b) =>
          new Date(a.node.first_publication_date).getTime() -
          new Date(b.node.first_publication_date).getTime()
      );
    } else if (sortBy === "z-a") {
      filteredBlogs.sort((a, b) =>
        b.node.data.title.text.localeCompare(a.node.data.title.text)
      );
    } else if (sortBy === "a-z") {
      filteredBlogs.sort((a, b) => {
        const charA = a.node.data.title.text.charAt(0);
        const charB = b.node.data.title.text.charAt(0);

        if (isNaN(charA) && !isNaN(charB)) {
          return -1;
        } else if (!isNaN(charA) && isNaN(charB)) {
          return 1;
        } else {
          return a.node.data.title.text.localeCompare(b.node.data.title.text);
        }
      });
    }

    setBlogs(filteredBlogs);
  }, [searchText, sortBy]);

  useEffect(() => {
    setCurrentPage(1);
    localStorage.removeItem("activeId");
  }, [activeTags]);

  const featuredArticle = data?.allPrismicBlog?.edges.find(
    (blg) => blg.node?.uid === "multimodal-ai-data-platform"
  );

  /**
   * @description Filters an array of blog objects based on a given tag (optional),
    and returns the filtered array. If the input array is null or empty,
    an empty array is returned.
   */
  const filteredBlogs =
    blogs && blogs.length
      ? blogs.filter((blog) => {
          const blogTags = blog.node.tags.map((tag) => tag.toLowerCase());
          const lowercaseTags = activeTags.map((tag) => tag.toLowerCase());

          return (
            lowercaseTags.length === 0 ||
            blogTags.some((blogTag) => lowercaseTags.includes(blogTag))
          );
        })
      : [];

  const handleModal = () => {
    setIsOpen(!isOpen);
  };

  const totalPages = Math.ceil(filteredBlogs.length / LIMIT) || 0;

  const _filteredBlogs = filteredBlogs.slice(
    (currentPage - 1) * LIMIT,
    currentPage * LIMIT
  );

  const _filterBlogs = _filteredBlogs;
  const trendingArticles = data.allPrismicBlog?.edges
    .filter((blog) => blog?.node?.data?.is_trending)
    .sort((a, b) => a.node.data.is_trending - b.node.data.is_trending)
    .slice(0, 7);
  const handleRemoveTag = (removedTag) => {
    if (removedTag === sortBy) {
      setSortBy("");
    } else {
      setActiveTags(activeTags.filter((tag) => tag !== removedTag));
    }
  };

  const allFilters = [...activeTags, sortBy].filter(Boolean);

  return (
    <article>
      <BlogHero {...{ featuredArticle, trendingArticles }} />
      <section className="px-4 mx-auto lg:max-w-6xl sm:px-12 xl:px-0">
        <section className="my-10">
          <Explore />
        </section>

        <Filter
          {...{
            setSearchText,
            searchText,
            tags: data?.allPrismicBlog?.distinct,
            activeTags,
            setActiveTags,
            sortBy,
            setSortBy,
          }}
        />

        {!!allFilters.length && (
          <section className="flex flex-wrap gap-4 mt-4">
            {allFilters.map((tag) => (
              <div
                className="flex items-center gap-1.5 bg-gray-1600 px-3 py-1.5 rounded-[20px] cursor-pointer"
                onClick={() => handleRemoveTag(tag)}
              >
                <CloseIcon />
                <span className="text-base font-medium text-gray-600 uppercase tracking-widest_1">
                  {tag}
                </span>
              </div>
            ))}
          </section>
        )}

        <div className="">
          <div className="pb-12 md:pb-20 ">
            <div className="flex mt-5 lg:flex lg:justify-center">
              <div className="w-full">
                <section className="grid max-w-6xl grid-cols-1 gap-6 mx-auto sm:grid-cols-2 lg:grid-cols-3">
                  {!_filterBlogs?.length && (
                    <h4 className="h4 !font-bold mb-4">No Blog Found</h4>
                  )}

                  {_filterBlogs.map((blog, index) => {
                    return (
                      <BlogCard
                        searchText={searchText}
                        location={location}
                        key={`blog_card_${index}`}
                        blog={blog}
                      />
                    );
                  })}
                </section>
              </div>
            </div>

            <div className="flex justify-center mt-7 sm:mt-14 lg:mt-18">
              {!!_filterBlogs?.length && (
                <Pagination
                  className="pagination-bar"
                  currentPage={currentPage}
                  totalPages={totalPages}
                  pageSize={LIMIT}
                  onPageChange={(page) => setCurrentPage(page)}
                  siblingCount={isMobile ? 0 : 1}
                />
              )}
            </div>
          </div>
        </div>
        <section className="z-0 max-w-6xl pb-10 mx-auto lg:px-6 xl:px-0 lg:pb-20">
          <CustomerVerticalCard
            data={encord_other_products}
            descriptionClass={`min-h-12`}
          />
        </section>
        <AnnotateFooter
          heading=" Software To Help You Turn Your Data Into AI"
          description=" Forget fragmented workflows, annotation tools, and Notebooks for
              building AI applications. Encord Data Engine accelerates every
              step of taking your model into production."
          imageBG={true}
          setEmail={setEmail}
          openModal={toggleModal}
        />
        <BlogPopUpModal/>
        <DemoModal
          email={email}
          isOpen={isOpen}
          closeModal={toggleModal}
          location={location}
        />
      </section>
    </article>
  );
};

const Blog = (props) => {
  return (
    <ModalProvider>
      <Layout location={props?.location} overflow={false} theme="transparent">
        <BlogList {...props} />
      </Layout>
    </ModalProvider>
  );
};

export const blogData = graphql`
  query BlogQuery {
    prismicCaseStudies {
      data {
        body {
          ... on PrismicCaseStudiesDataBodyEncordOtherProducts {
            id
            slice_type
            primary {
              section_heading {
                html
                text
              }
            }
            items {
              card_description {
                html
                text
              }
              card_heading {
                html
                text
              }
              card_image {
                alt
                url
              }
              card_label {
                text
              }
              cta_link {
                url
              }
              cta_text {
                text
              }
              icon {
                alt
                url
              }
            }
          }
        }
      }
    }
    allPrismicBlog(
      filter: {
        tags: { ne: "Customers" }
        uid: {
          nin: [
            "video-what-the-european-ai-act-means-for-ai-developers"
            "enterprise-ai-in-production-luc-vincent"
            "webinar-workshop-workflows-and-active-learning"
            "conxai-case-study"
          ]
        }
      }
      sort: { first_publication_date: DESC }
    ) {
      edges {
        node {
          uid
          data {
            author {
              document {
                ... on PrismicAuthor {
                  uid
                  data {
                    linkedin_profile_link {
                      url
                    }
                    twitter_profile_link {
                      url
                    }
                    full_name {
                      text
                    }
                  }
                }
              }
            }
            content {
              text
            }
            image {
              url
              alt
              gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED)
              dimensions {
                height
                width
              }
            }
            sub_image {
              gatsbyImageData(
                width: 368
                height: 270
                sizes: "(min-width: 1360px) 368px, (min-width: 1040px) calc(16vw + 154px), (min-width: 640px) calc(50vw - 28px), calc(100vw - 32px)"
                placeholder: BLURRED
                layout: CONSTRAINED
              )
              url
              alt
            }
            title {
              text
            }
            read_time
            custom_publish_date
            is_trending
          }
          first_publication_date
          tags
        }
      }
      distinct(field: { tags: SELECT })
    }
  }
`;

export default Blog;

export const Head = () => {
  return (
    <SEO
      title="Encord’s Blog | Unlock data-centric AI"
      description="Thought leadership from leading innovators in AI & Encord product updates."
    />
  );
};
